<template>
	<Toast />
	<div class="grid">
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Occupied</span>
						<div class="text-900 font-medium text-xl">{{ state.occupiedSiteCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-red-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-map-marker text-red-500 text-xl"></i>
					</div>
				</div>
				<span class="text-500">of </span>
				<span class="text-black-500 font-medium">{{ state.totalSiteCount }} </span>
				<span class="text-500"> total spaces</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Paid</span>
						<div class="text-900 font-medium text-xl">{{ state.paidSiteCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-green-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-dollar text-green-500 text-xl"></i>
					</div>
				</div>
				<span class="text-500">of </span>
				<span class="text-black-500 font-medium">{{ state.occupiedSiteCount }} </span>
				<span class="text-500"> occupied spaces</span>
			</div>
		</div>
		<div class="col-12 lg:col-6 xl:col-3">
			<div class="card mb-0">
				<div class="flex justify-content-between mb-3">
					<div>
						<span class="block text-500 font-medium mb-3">Active Payments</span>
						<div class="text-900 font-medium text-xl">{{ state.activeReservationCount }}</div>
					</div>
					<div class="flex align-items-center justify-content-center bg-cyan-100 border-round" style="width:2.5rem;height:2.5rem">
						<i class="pi pi-dollar text-cyan-500 text-xl"></i>
					</div>
				</div>
				<span class="text-500">of </span>
				<span class="text-black-500 font-medium">{{ state.todaysReservationCount }}  </span>
				<span class="text-500"> payments made today</span>
			</div>
		</div>

		<div class="col-12 xl:col-12">
			<div class="card">
				<h5>Recent Payments</h5>
				<DataTable :value="state.reservations" :rows="10" :paginator="true" responsiveLayout="scroll"  sortField="reservation_id" :sortOrder="-1">
					<Column field="reservation_id" header="Id"></Column>
					<Column field="space_name" header="Space"></Column>
					<Column field="payment_type" header="Payment"></Column>
					<Column field="fybrtag" header="Tag"></Column>
					<Column header="From" style="min-width:12rem;">
						<template #body="slotProps">
							{{utils.formatDateTime(slotProps.data.from_ts)}}
						</template>
					</Column>
					<Column header="Until" style="min-width:12rem;">
						<template #body="slotProps">
							{{utils.formatDateTime(slotProps.data.to_ts)}}
						</template>
					</Column>
					<Column header="Total">
						<template #body="slotProps">
							<span class="p-column-title">Until</span>
							{{utils.formatCurrency(slotProps.data.cost)}}
						</template>
					</Column>
					<Column>
						<template #body="slotProps">
							<span v-if="utils.timeNotPassed(slotProps.data.to_ts) && slotProps.data.is_active" :class="'payment-badge status-paid'">ACTIVE</span>
						</template>
					</Column>
				</DataTable>
			</div>
		</div>
	</div>
</template>

<script>
import { onBeforeUnmount, onMounted, reactive, watch, watchEffect } from 'vue';
import { useToast } from 'primevue/usetoast';
import { useApi } from '@/composables/useApi';
import { reservationsList, sitesList } from '@/api/interface';
import utils from '@/utils';

export default {
	name: 'Dashboard',

	setup() {
		const state = reactive({
			reservations: null,
			todaysReservationCount: null,
			activeReservationCount: null,
			sites: null,
			totalSiteCount: null,
			occupiedSiteCount: null,
			paidSiteCount: null
		});
		const toast = useToast();
		const { api_error, callApi } = useApi();
		const jwt = localStorage.getItem('jwt');
		let refreshInterval = null;

		const refresh = () => {
			callApi(reservationsList(jwt), response => {
				state.reservations = response;

				const todaysReservations = response.filter(reservation => {
					return utils.hasTodaysDate(reservation.from_ts);
				});
				state.todaysReservationCount = todaysReservations.length;

				const activeReservations = response.filter(reservation => {
					return utils.timeNotPassed(reservation.to_ts);
				});
				state.activeReservationCount = activeReservations.length;
			})

			callApi(sitesList(jwt), response => {
				state.sites = response;
				state.totalSiteCount = response.length;

				const occupiedSites = response.filter(site => {
					return site.occupancy === 'OCCUPIED';
				});
				state.occupiedSiteCount = occupiedSites.length;

				const paidSites = response.filter(site => {
					return site.payment_status === 'PAID';
				});
				state.paidSiteCount = paidSites.length;
			})
		}

		onMounted(() => {
			refreshInterval = setInterval(function () {
				refresh();
			}.bind(this), 5000);
		});

		watch(api_error, api_error => {
			if(api_error) {
				toast.add({severity: 'error', summary: 'API Error', detail:api_error, life: 5000});
			}
		});

		watchEffect(() => {
			if(state.reservations === null) {
				callApi(reservationsList(jwt), response => {
					state.reservations = response;

					const todaysReservations = response.filter(reservation => {
						return utils.hasTodaysDate(reservation.from_ts);
					});
					state.todaysReservationCount = todaysReservations.length;

					const activeReservations = response.filter(reservation => {
						return utils.timeNotPassed(reservation.to_ts);
					});
					state.activeReservationCount = activeReservations.length;
				})
			}

			if(state.sites === null) {
				callApi(sitesList(jwt), response => {
					state.sites = response;
					state.totalSiteCount = response.length;

					const occupiedSites = response.filter(site => {
						return site.occupancy === 'OCCUPIED';
					});
					state.occupiedSiteCount = occupiedSites.length;

					const paidSites = response.filter(site => {
						return site.payment_status === 'PAID';
					});
					state.paidSiteCount = paidSites.length;
				})
			}
		});

		onBeforeUnmount(() => {
			clearInterval(refreshInterval);
		});

		return { state, utils }
	}
}
</script>

<style scoped lang="scss">
@import '../assets/styles/badges.scss';
</style>